import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import FAQ from "../components/faq"
import VSL from "../components/sales-pages/vsl"
import LiveChat from "../components/sales-pages/liveChat"
import { trackCustomEvent } from "../utils/analytics"
import Image from "gatsby-image"
import { useStaticQuery, graphql, Link } from "gatsby"
import { useAuth } from "gatsby-theme-firebase"
import TestimonialSlider from "../components/testimonials"
import resultsIMG from "../../content/assets/icons/results.svg"
import skillsIMG from "../../content/assets/icons/skills.svg"
import communityIMG from "../../content/assets/icons/community.svg"
import { navigate } from "gatsby"
import RawLoginModal from "../components/sales-pages/loginModal"

export default () => {
  const images = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          description
        }
      }
      discord: file(relativePath: { eq: "education/discord.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1920, maxHeight: 1080) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      soundOn: file(absolutePath: { regex: "/sound-on.png/" }) {
        childImageSharp {
          fluid(maxHeight: 300) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      playButton: file(absolutePath: { regex: "/play-button.png/" }) {
        childImageSharp {
          fluid(maxWidth: 200) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      first30days: file(relativePath: { eq: "education/30days.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1920, maxHeight: 1080) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      ammp: file(relativePath: { eq: "education/ammp.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1920, maxHeight: 1080) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      smam: file(relativePath: { eq: "education/smam.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1920, maxHeight: 1080) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      bbb: file(relativePath: { eq: "education/bbb.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1920, maxHeight: 1080) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      cfw101: file(relativePath: { eq: "education/cfw101.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1920, maxHeight: 1080) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)
  const [toggleLogin, setToggleLogin] = React.useState(false)
  const { isLoggedIn } = useAuth()

  const ctaClick = () => {
    if (isLoggedIn) return navigate("/education")
    setToggleLogin(true)
    trackCustomEvent({
      category: "Button",
      action: "CTA",
    })
  }

  return (
    <Layout>
      <SEO
        title={`Millionaire Millennial: ${images.site.siteMetadata.description}`}
      />
      {toggleLogin && (
        <RawLoginModal
          setToggleLogin={setToggleLogin}
          isLoggedIn={isLoggedIn}
          course={{}}
        />
      )}

      <div>
        <div className="row">
          <div className="column50 tagline">
            <h1>
              <div className="pre-tagline">Learn Valuable Skills From An</div>
              Expert Entrepreneur Who's Actually Done It
            </h1>
            <h2 className="pre-tagline">So You Can Get the Results You Want</h2>
            <div className="post-tagline">
              (Even if You Have No Business Experience AT ALL!)
            </div>
            <div className="desktop-only">
              <button className="cta-button" onClick={ctaClick}>
                {isLoggedIn ? "View Classes" : "Get Started"}
              </button>
              <div className="post-cta">
                <i>*Unlimited Access to ALL Classes*</i>
              </div>
            </div>
          </div>
          <div className="column50">
            <VSL vidLink="416500990" />
            <div className="mobile-only" style={{ textAlign: "center" }}>
              <button className="cta-button" onClick={ctaClick}>
                {isLoggedIn ? "View Classes" : "Get Started"}
              </button>
              <div className="post-cta">
                <i>*Unlimited Access to ALL Classes*</i>
              </div>
            </div>
          </div>
        </div>
        <br />
        <hr />
        <div className="row" style={{ margin: "5rem 0" }}>
          <div className="column50 card">
            <div className="card-tagline">Results Focused</div>
            <p>
              All our classes and lectures are concise and to the point with the
              sole goal of getting you the results you want out of your online
              business.
              <br />
              <br /> Your success in business is our #1 priority.
            </p>
          </div>
          <div className="column50" style={{ textAlign: "center" }}>
            <img
              className="svg-image"
              src={resultsIMG}
              alt="Results"
              height="200px"
            />
          </div>
        </div>
        <div className="row" style={{ margin: "5rem 0" }}>
          <div
            className="column50 desktop-only"
            style={{ textAlign: "center" }}
          >
            <img
              className="svg-image"
              src={skillsIMG}
              alt="Skills"
              height="200px"
            />
          </div>
          <div className="column50 card">
            <div className="card-tagline">Real-Life Actionable Skills</div>
            <p>
              Learn the skills you need to go from a <i>'Wantrepreneur' </i> to
              the real deal. Quit 'aspiring' to be an entrepreneur and take the
              steps to actually BE one.
              <br />
              <br /> No useless information is availiable here.
            </p>
          </div>
          <div className="column50 mobile-only" style={{ textAlign: "center" }}>
            <img
              className="svg-image"
              src={skillsIMG}
              alt="Skills"
              height="200px"
            />
          </div>
        </div>
        <div className="row" style={{ margin: "5rem 0" }}>
          <div className="column50 card">
            <div className="card-tagline">Powerful Community</div>
            <p>
              Included in every Millionaire Millennial membership is access to
              our private Discord channel where entrepreneurs and other
              successful business people hang out and chat throughout the day.
              <br />
              <br /> There's always someone that can help!
            </p>
          </div>
          <div className="column50" style={{ textAlign: "center" }}>
            <img
              className="svg-image"
              src={communityIMG}
              alt="community"
              height="200px"
            />
          </div>
        </div>
        <div className="row" style={{ margin: "5rem 0" }}>
          <h2>Here's what's included:</h2>
          <div className="row" style={{ margin: "1rem 0" }}>
            <div className="column30">
              <Image fluid={images.discord.childImageSharp.fluid} />
            </div>
            <div className="column70">
              <h3 style={{ padding: "0 20px" }}>Discord Channel Community</h3>
              <ul className="fancy-checks">
                <li>
                  Drop in anytime to the Millionaire Millennial Mentoring
                  Discord channel where you can hop into a voice or text chat
                  with other successful business owners and entrepreneurs.
                </li>
                <li>
                  Build relationships and forge new perspectives by hanging out
                  with other like-minded people. Bounce ideas off other
                  individuals doing similar business models.
                </li>
                <li>
                  You can find the invitation link on the{" "}
                  <Link to="/community">Community</Link> page once you create a
                  Millionaire Millennial account. You'll be able to access it
                  anytime from a browser, PC, or phone/tablet!
                </li>
              </ul>
            </div>
          </div>
          <div className="row" style={{ margin: "1rem 0" }}>
            <div className="column30">
              <Image fluid={images.first30days.childImageSharp.fluid} />
            </div>
            <div className="column70">
              <h3 style={{ padding: "0 20px" }}>First 30 Days</h3>
              <ul className="fancy-checks">
                <li>
                  Learn and obtain the mindset of the most successful people on
                  the planet so you can live the life you truely dream of.
                </li>
                <li>
                  Find your 'Telos' and utilize it to easily make the right
                  decisions so you can consistantly excel in your career and
                  life.
                </li>
                <li>
                  From persuasion and communicating to investing and diet - you
                  will learn what it takes to be the top 1%.
                </li>
              </ul>
            </div>
          </div>
          <div className="row" style={{ margin: "1rem 0" }}>
            <div className="column30">
              <Image fluid={images.cfw101.childImageSharp.fluid} />
            </div>
            <div className="column70">
              <h3 style={{ padding: "0 20px" }}>Coding For the Web 101</h3>
              <ul className="fancy-checks">
                <li>
                  Begin learning to code and understand the language of the
                  internet (HTML & CSS) with no prior knowledge of web
                  development. <i>100% beginner friendly!</i>
                </li>
                <li>
                  Build a complete website with 0 prior experience writing code
                  so you can begin creating software and websites at ease.
                </li>
                <li>
                  How to setup and access a ton of free tools to make coding
                  100X easier and faster. You'll have your first site LIVE
                  online in a just a few hours!
                </li>
              </ul>
            </div>
          </div>

          <div className="row" style={{ margin: "1rem 0" }}>
            <div className="column30">
              <Image fluid={images.ammp.childImageSharp.fluid} />
            </div>
            <div className="column70">
              <h3 style={{ padding: "0 20px" }}>
                Amazon Millionaire Mentorship Program
              </h3>
              <ul className="fancy-checks">
                <li>
                  Learn to build a profitable Amazon FBA business and scale to
                  over $10,000/mo so you can have a consistent income online.
                </li>
                <li>
                  Deeply understand the ins-and-outs and pitfalls of an Amazon
                  business so you can avoid making costly mistakes.
                </li>
                <li>
                  No experience with eCommerce required - this program teaches
                  ALL 4 methods to selling so you'll have a solid framework to
                  build on.
                </li>
              </ul>
            </div>
          </div>
          <div className="row" style={{ margin: "1rem 0" }}>
            <div className="column30">
              <Image fluid={images.smam.childImageSharp.fluid} />
            </div>
            <div className="column70">
              <h3 style={{ padding: "0 20px" }}>
                Social Media Affiliate Marketing
              </h3>
              <ul className="fancy-checks">
                <li>
                  Learn to leverage the power of social media using affiliate
                  marketing in a way not many people know about.
                </li>
                <li>
                  Create a long-lasting passive income that continues to pay you
                  everyday for years to come.
                </li>
                <li>
                  From niche selection to funnel building to conversions and
                  payday, S.M.A.M. covers everything you need to build an
                  affiliate business.
                </li>
              </ul>
            </div>
          </div>

          <div className="row" style={{ margin: "1rem 0" }}>
            <div className="column30">
              <Image fluid={images.bbb.childImageSharp.fluid} />
            </div>
            <div className="column70">
              <h3 style={{ padding: "0 20px" }}>
                COMING SOON: Business Builder Basics
              </h3>
              <ul className="fancy-checks">
                <li>
                  Learn everything you need to build a business in ANY niche
                  with ANY product you can dream of.
                </li>
                <li>
                  How to find your ideal customer and attract them to your
                  business to buy your product(s).
                </li>
                <li>
                  How to handle accounting, taxes, and all the other little
                  things no one likes in a way that is incredibly easy.
                </li>
              </ul>
            </div>
          </div>
        </div>
        <hr />
        <div style={{ maxWidth: "400px", margin: "4rem auto" }}>
          <button className="cta-button" onClick={ctaClick}>
            {isLoggedIn ? "View Classes" : "Get Started"}
          </button>
        </div>
        <br />
        <hr />
        <div className="row card">
          <h2>Here's what our students have said:</h2>
        </div>
        <div className="row" style={{ margin: "1rem 0" }}>
          <div className="column50 card">
            <div className="card-tagline">Daniel Roiter</div>
            <p className="quote">
              "Every single video in the course was super informative... He made
              it so easy to understand everything in the videos. The last 8
              months or so have been the <b>greatest period of my life.</b>"
            </p>
          </div>
          <div className="column50" style={{ padding: "1rem" }}>
            <div className="video-frame">
              <iframe
                src="https://player.vimeo.com/video/415556935"
                width="100%"
                height="250"
                frameBorder="0"
                allow="autoplay; fullscreen"
                allowFullScreen
              ></iframe>
            </div>
          </div>
        </div>
        <div className="row" style={{ margin: "1rem 0" }}>
          <div className="column50 desktop-only" style={{ padding: "1rem" }}>
            <div className="video-frame">
              <iframe
                src="https://player.vimeo.com/video/413184627"
                width="100%"
                height="250"
                frameBorder="0"
                allow="autoplay; fullscreen"
                allowFullScreen
              ></iframe>
            </div>
          </div>
          <div className="column50 card">
            <div className="card-tagline">Geordan Strain</div>
            <p className="quote">
              "That first video was just so real & true - talking about the
              Basic and Supreme mode. For people that are just starting or need
              a mentor to teach them, you have captured so much of the
              information"
            </p>
          </div>
          <div className="column50 mobile-only" style={{ padding: "1rem" }}>
            <div className="video-frame">
              <iframe
                src="https://player.vimeo.com/video/413184627"
                width="100%"
                height="250"
                frameBorder="0"
                allow="autoplay; fullscreen"
                allowFullScreen
              ></iframe>
            </div>
          </div>
        </div>
        <p style={{ fontSize: "1.5rem" }}>...and even more:</p>
        <br />
        <TestimonialSlider />
        <br />
        <hr />
        <br />
        <FAQ />
        <br />
        <hr />
        <div style={{ maxWidth: "400px", margin: "4rem auto" }}>
          <button className="cta-button" onClick={ctaClick}>
            {isLoggedIn ? "View Classes" : "Get Started"}
          </button>
        </div>
      </div>
      <LiveChat />
    </Layout>
  )
}
